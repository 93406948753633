import NextRouter from "next/router";
import {ServerResponse} from "http";

// Constants
import {HttpStatusCode} from "../../constants";

// Models
import RouterInterface from "./RouterInterface";

export default {
    /**
     * Performing redirects
     *
     * @param {string} location
     * @param {ServerResponse} response
     */
    redirect: function (location: string, response?: ServerResponse): Promise<boolean> | undefined {
        if (typeof response !== "undefined") {
            response.writeHead(HttpStatusCode.SEE_OTHER, {
                Location: location,
            });
            response.end();
            response.finished = true;
        } else {
            return NextRouter.replace(location);
        }
    },
} as RouterInterface;
