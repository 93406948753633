export const Routes = {
    INDEX: "/",
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    RESET_PASSWORD: "/reset-password",
    CHANGE_PASSWORD: "/change-password",
    PATIENTS: "/patients",
    SINGLE_PATIENT: "/patients/single-patient",
    SINGLE_PATIENT_RECORD: "/patients/single-patient/record",
    GROUPS: "/groups",
    HISTORY: "/history",
    MOBILE: "/mobile",
    ECGVIEWER: "/ecgviewer",
};

export const UNKNOWN_PATIENT = "unknown";

export const HttpStatusCode = {
    OK: 200,
    SEE_OTHER: 303,
    NOT_FOUND: 404,
    CREATED: 201,
    NO_CONTENT: 204,
    FOUND: 301,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
};

export const RequestHeader = {
    ACCEPT: "Accept",
    CONTENT_TYPE: "Content-Type",
    AUTHORIZATION: "Authorization",
    ACCEPT_LANGUAGE:"Accept-Language"
};

export const ResponseHeader = {
    CONTENT_DISPOSITION: "Content-Disposition",
};

export const RequestMethod = {
    PUT: "PUT",
    POST: "POST",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

export const ContentType = {
    PDF: "application/pdf",
    OCTET_STREAM: "application/octet-stream",
    JSON: "application/json",
    FORM_DATA: "multipart/form-data",
    CONTENT_DISPOSITION: "content-disposition",
};

export enum Role {
    USER = "USER",
    PATIENT = "PATIENT",
    DOCTOR = "DOCTOR",
    DEMO_PATIENT = "DEMO_PATIENT",
    COMPANY_PATIENT = "COMPANY_PATIENT",
    DEMO_DOCTOR = "DEMO_DOCTOR",
}

export enum Sex {
    MALE = "MALE",
    FEMALE = "FEMALE",
    BOTH = "BOTH",
    OTHER = "OTHER",
}

export enum UserIdentifier {
    PHONE = "Phone",
    EMAIL = "Email",
}

export enum SportType {
    SPORT = "SPORT",
    STANDARD = "STANDARD",
    ENDURANCE = "ENDURANCE",
}

export const TranslationNamespace = {
    COMMON: "common",
};

export const DoctorSpecialization = [
    {
        id: "GP",
        title: "GP",
    },
    {
        id: "CARDIOLOGIST",
        title: "cardiologist",
    },
    {
        id: "SPORTS_DOCTOR",
        title: "sports_doctor",
    },
    {
        id: "NUTRITIONIST",
        title: "nutritionist",
    },
]